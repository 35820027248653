<template>
  <v-card>
    <v-card-title>Download Labels</v-card-title>
    <v-card-actions>
      <v-radio-group v-model="exportOptionSelected">
        <v-radio
          v-for="o in optionExport"
          :key="o.value"
          :label="o.label"
          :value="o.value"
        ></v-radio>
      </v-radio-group>
    </v-card-actions>
    <v-card-actions v-if="exportOptionSelected === 'found-on-date-range'">
      <v-menu
        v-model="showCreatedAtOrderDatePicker"
        :close-on-content-click="false"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field label="Ngày tạo order" class="mr-2" @click:clear="clearCreatedOrderSearch()" clearable dense
                        outlined readonly
                        v-on="on" v-bind="attrs" :value="DateRangeText(createdDates)"
                        @click="showCreatedAtOrderDatePicker = true"></v-text-field>
        </template>
        <v-date-picker v-model="createdDates" range></v-date-picker>
      </v-menu>
    </v-card-actions>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="onClickExport">Download</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import indexMixin from '../indexMixin';
import * as JsZip from "jszip";
import FileSaver from 'file-saver';

export default {
  name: 'ModalDownloadLabel',
  props: ['onCancel', 'searchData', 'option', 'statusSync', 'picked'],
  mixins: [indexMixin],
  data: () => ({
    importFile: null,
    exportOptionSelected: 'all-selected',
    showCreatedAtOrderDatePicker: false,
    createdDates: [],
    searchFieldExport: {},
    optionExport: [
      {
        value: 'all-selected',
        label: "Download label đã tick chọn trên màn hình",
      },
      {
        value: 'current-page',
        label: "Download label page hiện tại",
      },
    ]
  }),
  computed: {
    DateRangeText() {
      return dateArr => {
        return dateArr.join(' ~ ')
      }
    },
  },
  methods: {
    ...mapActions({
      exportListItems: 'order/exportItem',
      onSearchOrders: 'order/onSearchOrders',
      getMultiLabels: 'order/getMultiLabels',
    }),
    clearCreatedOrderSearch() {
      this.searchFieldExport.created_date_to.value = '';
      this.searchFieldExport.created_date_from.value = '';
    },
    async isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    async downloadLabels(ids) {
      this.getMultiLabels({ids: ids, type: 'one-file-pdf'}).then(res => {
        let fileName = res.headers && res.headers['content-disposition'] ? res.headers['content-disposition'].replace('attachment; filename=', '') : null;
        if (!fileName) {
          fileName = 'labels-' + new Date().toISOString().substring(0, 19).replaceAll('-', '').replaceAll(':', '').replaceAll('T', '') + '.pdf';
        }
        FileSaver.saveAs(res.data, fileName)
      }).catch(err => {
        this.noticeError(err.message)
      });
    },
    async downloadLabelsPicked() {
      if (this.picked.length === 0) {
        this.onCancel()
        return this.noticeWarning('Item not found');
      }
      let ids = this.picked.map(i => i.id);
      await this.downloadLabels(ids)
      this.onCancel()
    },
    async searchAndExportOrder(currentPage = false) {
      let res = [];
      if (currentPage) res = await this.searchOrderHandleExport(this.searchFieldExport, this.option);
      else {
        let optionOpt = JSON.parse(JSON.stringify(this.option))
        optionOpt.itemsPerPage = -1;
        res = await this.searchOrderHandleExport(this.searchFieldExport, optionOpt);
      }
      if (res && res.length > 0) {
        const ids = res.map((o) => o.id)
        if (ids.length < 1) {
          this.onCancel()
          return this.noticeWarning('Item not found');
        }
        await this.downloadLabels(ids)
        this.onCancel()
      } else {
        this.onCancel()
        return this.noticeWarning('Item not found');
      }
    },
    async onClickExport() {
      if (this.exportOptionSelected === 'all-selected') await this.downloadLabelsPicked();
      else if (this.exportOptionSelected === 'current-page') await this.searchAndExportOrder(true);
    }
  },
  watch: {
    createdDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFieldExport.created_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFieldExport.created_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    statusSync: {
      handler(val) {
        console.log('this.searchData', this.searchData)
        if (val) this.searchFieldExport = JSON.parse(JSON.stringify(this.searchData))
        else this.exportOptionSelected = 'all-selected'
      },
      deep: true,
      immediate: true,
    }
  },
}
</script>
