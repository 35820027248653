<template>
  <v-card class="px-5">
    <v-card-title>Choose display template</v-card-title>
    <v-autocomplete v-for="service in services" :key="service.id" dense outlined :label="service.name" required
                    :items="listPrintTemplates" item-value="name" item-text="name" v-model="printTemplate[service.id]"/>
    <v-card-actions class="px-0 pb-5">
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="clickMultiplePrint">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'ConfirmPrintLabel',
  props: ['idsToPrintLabel', 'onCancel'],
  data: () => ({
    printTemplate: {},
  }),
  computed: {
    ...mapGetters({
      services: 'service/all',
      listPrintTemplates: 'service/listPrintTemplates',
      printTemplateDefault: 'service/printTemplateDefault'
    })
  },
  methods: {
    clickMultiplePrint() {
      if (this.idsToPrintLabel.length < 1) return alert('Bạn chưa chọn order nào')
      let ids = this.idsToPrintLabel.join(',')
      let routeData = this.$router.resolve({
        name: 'PrintOrdersLabel',
        params: {
          orderIds: ids,
          printTemplate: JSON.stringify(this.printTemplate)
        }
      });
      window.open(routeData.href, '_blank');
    }
  },
  created() {
    this.printTemplate = JSON.parse(JSON.stringify(this.printTemplateDefault));
  }
}
</script>
