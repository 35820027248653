<template>
  <v-card>
    <v-card-title>Do you want to pay for {{ idsToPay.length > 1 ? 'these' : 'this' }} order?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processPay">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'PayOrder',
  props: ['idsToPay', 'onPaid', 'onCancel'],
  methods: {
    ...mapActions({
      changeOrderStatus: 'order/changeOrderStatus',
      payMultiOrders: 'payment/payOrders'
    }),
    async processPay() {
      if (!this.idsToPay) return this.noticeWarning('Item not found');
      this.payMultiOrders({ids: this.idsToPay}).then(() => {
        this.onPaid();
      });
    }
  }
}
</script>
