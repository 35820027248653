<template>
  <v-card tile :elevation="0" class="crud-box list-orders px-4">
    <h1>Manage Orders</h1>
    <v-layout v-if="!isCollaborator && !isPackager" wrap class="pt-4">
      <v-btn color="success" class="mr-2" v-if="!isSale" @click="onClickNew">
        <v-icon>mdi-plus</v-icon>
        New
      </v-btn>
      <v-btn color="primary" outlined class="mr-2" @click="onClickImport">
        <v-icon>mdi-database-import</v-icon>
        Import
      </v-btn>
      <v-btn color="primary" outlined class="mr-2" @click="exportItems()">
        <v-icon>mdi-database-export</v-icon>
        Export
      </v-btn>
      <v-btn color="primary" v-if="pickedItems.length > 0" class="mr-2" @click="onClickDownloadLabel">
        <v-icon>mdi-database-export</v-icon>
        Download Label
      </v-btn>
      <v-btn color="teal" v-if="!isSale" class="mr-2 text-white btn-unpaid-order" @click="onClickSelectOrderUnpaid">
        <v-icon>mdi-check</v-icon>
        Select Unpaid Order
      </v-btn>
      <v-btn color="error" v-if="pickedItems.length > 0 && !isSale" class="mr-2" @click="onClickDeleteAll">
        <v-icon>mdi-cancel</v-icon>
        Cancel Orders
      </v-btn>
      <v-btn v-if="pickedItems.length > 0 && !isSale"
             color="info" class="mr-2"
             @click="onClickPayMultiOrders">
        <v-icon>mdi-currency-usd</v-icon>
        Pay Orders
      </v-btn>
      <v-btn color="primary" v-if="pickedItems.length > 0" class="mr-2" @click="onClickPrintLabel">
        <v-icon>mdi-printer</v-icon>
        Print Label
      </v-btn>
      <v-btn
        v-if="pickedItems.length > 0 && canBuyTracking && ($auth.user().role_id== CONSTANTS.ROLES.SUPER_ADMINISTRATOR || isSale || isManager || isSupervisor)"
        color="primary"
        class="mr-2"
        @click="onClickBuyTracking">
        Buy Tracking
      </v-btn>
    </v-layout>
    <v-layout class="switch-search">
      <v-switch
        v-model="showSearch"
        :label="`Show field search`"
      ></v-switch>
    </v-layout>
    <v-layout v-show="showSearch" wrap class="px-4 pt-4">
      <!---->
      <v-layout wrap class="search-fields search-fields-page">
        <v-text-field dense outlined @keyup.enter="doSearch"
                      class="mr-2" label="Search Customer"
                      v-if="canSearchCustomer"
                      v-model="searchFields['customer.email'].value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                      label="Search Order Name"
                      v-model="searchFields.name.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                      label="Search Order Code"
                      v-model="searchFields.code.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                      label="Search Tracking Number"
                      v-model="searchFields.tracking_number.value"/>
        <!--        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"-->
        <!--                        @click:clear="clearInternalWarehouseSearch()" clearable-->
        <!--                        v-if="!isCustomer"-->
        <!--                        label="Internal Warehouse"-->
        <!--                        item-value="id"-->
        <!--                        item-text="name"-->
        <!--                        :items="internalWarehouses" v-model="searchFields.internal_warehouse_id.value"/>-->
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                        @click:clear="clearPublicWarehouseSearch()" clearable
                        v-if="!isCustomer"
                        label="Public Warehouse"
                        item-value="id"
                        item-text="name"
                        :items="publicWarehouses" v-model="searchFields.warehouse_id.value"/>
        <v-menu
          v-model="showBuyTrackingDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Ngày mua tracking" @click:clear="clearBuyTrackingSearch()" class="mr-2" clearable dense
                          outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(buyTrackingDates)"
                          @click="showBuyTrackingDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="buyTrackingDates" range></v-date-picker>
        </v-menu>
        <v-menu
          v-model="showCreatedAtOrderDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Ngày tạo order" class="mr-2" @click:clear="clearCreatedOrderSearch()" clearable dense
                          outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(createdDates)"
                          @click="showCreatedAtOrderDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="createdDates" range></v-date-picker>
        </v-menu>
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                        label="Search Status"
                        :items="listOrderStatus" v-model="searchFields.status.value"/>
        <v-autocomplete v-if="!isCustomer" dense outlined @keyup.enter="doSearch"
                        class="mr-2" label="Buy Tracking Status"
                        :items="listBuyTrackingStatus" v-model="searchFields.buy_tracking_status.value"/>
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                        label="Search Service" style="width: 175px"
                        :items="listServices" v-model="searchFields.service_id.value"/>
        <v-autocomplete v-if="isSuperAdmin" @click:clear="searchFields.collaborator_id.value = ''" clearable dense
                        outlined @keyup.enter="doSearch" class="mr-2"
                        label="Collaborator" style="width: 175px"
                        item-value="id"
                        item-text="name"
                        :items="collaborators" v-model="searchFields.collaborator_id.value"/>
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                        label="Receiver country" style="width: 175px" item-text="name"
                        :items="listCountry" v-model="searchFields.receiver_country_id.value" item-value="id"/>
        <v-menu
          v-model="showDepartDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Ngày Depart" class="mr-2" @click:clear="clearDepartDateSearch()" clearable dense
                          outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(departDates)"
                          @click="showDepartDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="departDates" range></v-date-picker>
        </v-menu>
        <v-menu
          v-model="showDeliveredDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Ngày Delivered" @click:clear="clearDeliveredDateSearch()" class="mr-2" clearable dense
                          outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(deliveredDates)"
                          @click="showDeliveredDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="deliveredDates" range></v-date-picker>
        </v-menu>
        <v-menu
          v-model="showReceiveDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Receive Date" @click:clear="clearReceivedDateSearch()" class="mr-2" clearable dense
                          outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(receivedDates)"
                          @click="showReceiveDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="receivedDates" range></v-date-picker>
        </v-menu>
        <v-spacer style="flex-grow: 100000000000000 !important;"></v-spacer>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeaderFilterByRole" :items="listItems" :server-items-length="tblPagination.total"
                    :loading="tblLoading"
                    mobile-breakpoint="0"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems"
                    :show-select="!isCollaborator && !isPackager && tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>

        <template v-slot:item.name="{item}">
          <v-tooltip bottom v-if="item.note">
            <template v-slot:activator="{ on, attrs }">
              <div class="block-item-name" v-bind="attrs" v-on="on">
                <div class="item-name">{{ item.name }}</div>
                <div class="item-code">{{ item.code }}</div>
              </div>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
          <template v-else>
            <div class="block-item-name">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-code">{{ item.code }}</div>
            </div>
          </template>
        </template>

        <template v-slot:item.service_country="{item}">
          <v-tooltip bottom v-if="item.employee_note">
            <template v-slot:activator="{ on, attrs }">
              <div class="item-service-name" v-bind="attrs" v-on="on">
                {{ serviceGetName(item.service_id) }}
              </div>
            </template>
            <span>{{ item.employee_note }}</span>
          </v-tooltip>
          <template v-else>
            {{ serviceGetName(item.service_id) }}
          </template>
          <div class="small-text text-no-wrap">
            {{ item.receiver_country_id ? countryById(item.receiver_country_id).name : '' }}
          </div>
          <div class="internal-warehouse" v-if="!isCustomer">
            {{ item.internal_warehouse ? item.internal_warehouse.code : '' }}
          </div>
        </template>
        <template v-slot:item.warehouse_id="{item}">
          <div>{{
              item.warehouse_id && warehouseById(item.warehouse_id) ? warehouseById(item.warehouse_id).name : ''
            }}
          </div>
        </template>
        <template v-slot:item.fees="{item}">
          <div class="text-no-wrap font-weight-bold">
            <money-format :value="getTotalFee(item)" style="display: inline-block;"
                          :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
          </div>
          <div class="small-text text-no-wrap">
            Paid:
            <money-format :value="getPaidFee(item)" style="display: inline-block;" class="green--text"
                          :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
          </div>
        </template>
        <template v-slot:item.remain_fee="{item}">
          <div class="text-no-wrap font-weight-bold">
            <money-format :value="getRemainFee(item)" style="display: inline-block;"
                          :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
          </div>
          <div class="small-text text-no-wrap">&nbsp;</div>
        </template>
        <template v-slot:item.status="{item}">
          <div v-if="item.departed_date && item.status === CONSTANTS.ORDER_STATUS.PACKAGED_WAIT_TRANSIT">
            Departed
          </div>
          <div v-else v-html="orderGetStatusText(item.status).replace(' - ', '<br>')"></div>
          <div class="small-text text-no-wrap"
               v-if="[
                 CONSTANTS.ORDER_STATUS.PACKAGED_WAIT_TRANSIT,
                 CONSTANTS.ORDER_STATUS.TRANSITED_WAIT_COMPLETE,
                 CONSTANTS.ORDER_STATUS.COMPLETED
               ].indexOf(item.status) > -1">
            {{ orderGetClearanceStatusText(item.clearance_status) }}
            {{
              (item.clearance_status === CONSTANTS.ORDER_CLEARANCE_STATUS.PASSED && item.clearance_date)
                ? ' ngày ' + item.clearance_date.substr(5, 5).split('-').reverse().join('/')
                : ''
            }}
          </div>
        </template>

        <template v-slot:item.tracking_number="{item}">
          <div v-if="item.buy_tracking_status !== CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS">
            <span :class="{
            'orange--text' : item.buy_tracking_status !== CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_FAIL,
            'red--text' : item.buy_tracking_status === CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_FAIL
             }">
              Status: {{ buyTrackingGetStatusText(item.buy_tracking_status) }}
            </span>
          </div>
          <template v-else>
            <div>
              <a target="_blank"
                 :href="item.carrier === 'USPS' ? item.tracking_url : 'http://tracking.epacket.vn/#/tracking/manage?tracking_no=' + item.code">{{
                  item.tracking_number
                }}</a>
            </div>
            <div class="small-text text-no-wrap">
              {{ item.buy_tracking_number_date ? 'Buy at ' + dateFilter(item.buy_tracking_number_date) : '' }}
            </div>
            <div class="small-text text-no-wrap">
              {{ item.tracking_history ? 'Deliver status: ' + item.tracking_history.deliver_status : '' }}
            </div>
          </template>
        </template>

        <template v-slot:item.tracking_master="{item}">
          {{ item.big_container ? item.big_container.tracking_number : '' }}
        </template>

        <template v-slot:item.trace_date="{item}">
          <div>Pickup: {{ receivedDate(item) }}</div>
          <div>Departed: {{ departedDate(item) }}</div>
          <div>Delivered: {{ deliveredDate(item) }}</div>
        </template>
        <template v-slot:item.customer_info="{item}">
          {{ item.customer.email }}
          <v-tooltip bottom v-if="item.customer_note" max-width="200px">
            <template v-slot:activator="{ on, attrs }">
              <div class="order-customer-note" v-bind="attrs" v-on="on">
                <span class="truncate">
              Note: {{ item.customer_note }}
            </span>
              </div>
            </template>
            <span>{{ item.customer_note }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="onClickDetail(props)" v-bind="attrs" v-on="on" class="mr-2" color="primary">
                mdi-view-list
              </v-icon>
            </template>
            <span>Chi tiết đơn hàng</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canEditOrder(props)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="onClickEdit(props)" v-bind="attrs" v-on="on" class="mr-2" color="success">
                mdi-pencil
              </v-icon>
            </template>
            <span>Sửa đổi thông tin</span>
          </v-tooltip>
          <v-tooltip bottom v-if="needPayOrder(props) && !isSale">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="onClickPayOrder(props)" v-bind="attrs" v-on="on" color="error">mdi-currency-usd
              </v-icon>
            </template>
            <span>Thanh toán</span>
          </v-tooltip>
        </template>
        <template v-slot:footer.prepend>
          <div>
            <span>Total remaining fee: </span>
            <money-format :value="totalRemainingFee"
                          style="display: inline-block; font-weight: bold; color: darkred; font-size: 1.19rem"
                          :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-layout wrap class="px-4">
      <!--      <div style="display: flex;align-items: center;margin-right: 1rem;font-weight: bold;">-->
      <!--        <label>Search Fields:</label>-->
      <!--      </div>-->
      <v-checkbox v-model="searchFields.show_order_canceled.value" style="width: 195px; display: inline-block;">
        <template v-slot:label>
          <span class="red--text"><b>{{ searchFields.show_order_canceled.display_name }}</b></span>
        </template>
      </v-checkbox>
      <!--      <template v-for="(field, idx) in toggleableSearchFields">-->
      <!--        <v-checkbox v-if="!searchFields[field].display_role || searchFields[field].display_role.indexOf(UserRole) > -1"-->
      <!--                    v-model="searchFields[field].display" :key="idx" style="margin-left: 1rem">-->
      <!--          <template v-slot:label>-->
      <!--            {{ searchFields[field].display_name }}-->
      <!--          </template>-->
      <!--        </v-checkbox>-->
      <!--      </template>-->
    </v-layout>

    <v-dialog v-model="dialog.create" :width="'80%'">
      <CreateItem :onCreated="onCreated" :isCopyOrder="isCopyOrder" :showModal="dialog.create"
                  @resetFlagCopy="resetFlagCopy" :selectedItem="selectedItem"
                  :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'80%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"
                  :onClickPrintLable="onClickPrintLable" @clickCopy="clickCopy"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="'80%'">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :dialogUpdate="dialog.update"
                  :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.payOrders" :width="400">
      <PayOrder :idsToPay="idsToPay" :onPaid="onPaid" :onCancel="()=>{dialog.payOrders=false}"></PayOrder>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="600">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
    <v-dialog v-model="dialog.printLabel" :width="600">
      <ConfirmPrintLabel :idsToPrintLabel="idsToPrintLabel"
                         :onCancel="()=>{dialog.printLabel=false}"></ConfirmPrintLabel>
    </v-dialog>
    <v-dialog v-model="dialog.exportOrder" :width="600">
      <ModalExportOrder :picked="pickedItems" :statusSync="dialog.exportOrder" :option="tblOptions"
                        :searchData="searchFields" :onCancel="()=>{dialog.exportOrder=false}"></ModalExportOrder>
    </v-dialog>
    <v-dialog v-model="dialog.downloadLabel" :width="600">
      <ModalDownloadLabel :picked="pickedItems" :statusSync="dialog.downloadLabel" :option="tblOptions"
                          :searchData="searchFields" :onCancel="()=>{dialog.downloadLabel=false}"></ModalDownloadLabel>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import DeleteItem from "./Delete";
import PayOrder from "./PayOrder";
import ImportItem from "./Import";
import indexMixin from './indexMixin';
import ConfirmPrintLabel from "@/admin/pages/ManageOrders/PrintLabel/ConfirmPrintLabel";
import ModalExportOrder from "@/admin/pages/ManageOrders/Modal/ModalExportOrder";
import ModalDownloadLabel from "@/admin/pages/ManageOrders/Modal/ModalDownloadLabel";
import MoneyFormat from "vue-money-format";
import moment from 'moment'
import CONSTANTS from '@/config/constants'

export default {
  name: 'ManageOrders',
  components: {
    ConfirmPrintLabel,
    CreateItem,
    DetailItem,
    UpdateItem,
    DeleteItem,
    PayOrder,
    ImportItem,
    MoneyFormat,
    ModalExportOrder,
    ModalDownloadLabel,
  },
  mixins: [indexMixin],
  data: () => ({
    searchFields: {
      'customer.email': {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Customer Email',
        display_role: [CONSTANTS.ROLES.SUPER_ADMINISTRATOR, CONSTANTS.ROLES.RECEIVER, CONSTANTS.ROLES.PACKAGER]
      },
      name: {
        value: '',
        type: 'like',
        display: false,
        display_name: 'Order Name',
      },
      show_order_canceled: {
        value: false,
        type: '=',
        display: true,
        display_name: 'Show Orders Canceled',
        display_role: []
      },
      code: {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Order Code',
      },
      tracking_number: {
        value: '',
        type: 'like',
        display: false,
        display_name: 'Tracking Number',
      },
      status: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Order Status',
      },
      // internal_warehouse_id: {
      //   value: '',
      //   type: '=',
      //   display: true,
      //   display_name: 'Internal Warehouse',
      // },
      warehouse_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Public Warehouse',
      },
      receiver_country_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Receiver Country',
      },
      collaborator_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Collaborator',
      },
      buy_tracking_status: {
        value: '',
        type: '=',
        display: false,
        display_name: 'Buy Tracking Status',
      },
      service_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Service',
      },
      search_by_receiver: {
        value: false,
        type: '=',
        display: false,
        force_hidden: true
      },
      departed_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      departed_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      delivered_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      delivered_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      received_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      received_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      created_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      created_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      buy_tracking_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      buy_tracking_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
    },
    searchWith: 'customer;orderItems;bigContainer;orderFees;payments;internalWarehouse;TrackingHistory',
    tblHeader: [
      {
        text: 'No',
        value: 'no',
        sortable: false,
        groupable: false,
        width: '55px',
        align: 'start',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Service & Country',
        value: 'service_country',
      },
      // {
      //   text: 'Buy Tracking Status',
      //   value: 'buy_tracking_status',
      //   sortable: false
      // },
      {
        text: 'Tracking Number',
        value: 'tracking_number',
        sortable: false
      },
      {
        text: 'Total Fee',
        value: 'fees',
      },
      {
        text: 'Remain Fee',
        value: 'remain_fee',
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
        width: '155px',
      },
      {
        text: 'Warehouse',
        value: 'warehouse_id',
      },
      {
        text: 'Tracking Master',
        value: 'tracking_master',
        sortable: false
      },
      {
        text: 'Trace Date',
        value: 'trace_date',
        width: '166px',
      },
      {
        text: 'Customer Info',
        value: 'customer_info',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        groupable: false,
        width: '111px',
        align: 'center',
      }
    ],
    showBuyTrackingDatePicker: false,
    showDepartDatePicker: false,
    showDeliveredDatePicker: false,
    showReceiveDatePicker: false,
    showCreatedAtOrderDatePicker: false,
    buyTrackingDates: [],
    createdDates: [],
    departDates: [],
    deliveredDates: [],
    receivedDates: [],
    idsToPay: [],
    idsToExport: [],
    isCopyOrder: false,
    showSearch: true,
    loadingCollaborators: false,
    collaborators: [],
  }),
  computed: {
    ...mapGetters({
      items: 'order/all',
      tblPagination: 'order/pagination',
      services: 'service/all',
      serviceGetName: 'service/serviceName',
      listOrderStatus: 'order/listOrderStatus',
      orderGetStatusText: 'order/getStatusText',
      orderGetClearanceStatusText: 'order/getClearanceStatusText',
      buyTrackingGetStatusText: 'order/buyTrackingGetStatusText',
      listBuyTrackingStatus: 'order/listBuyTrackingStatus',
      countryById: 'country/getById',
      countries: 'country/all',
      authenticatedUser: 'user/authenticatedUser',
      warehouses: 'warehouse/all',
      warehouseById: 'warehouse/getById',
    }),
    tblHeaderFilterByRole() {
      const header = JSON.parse(JSON.stringify(this.tblHeader));
      if (this.isCustomer) {
        const findIndexStatusTracking = header.findIndex((t) => t.value === 'buy_tracking_status');
        if (findIndexStatusTracking > -1) {
          header.splice(findIndexStatusTracking, 1);
        }
        const findIndexCustomerEmail = header.findIndex((t) => t.value === 'customer_email');
        if (findIndexCustomerEmail > -1) {
          header.splice(findIndexCustomerEmail, 1);
        }
        const findIndexWarehouseName = header.findIndex((t) => t.value === 'warehouse_name');
        if (findIndexWarehouseName > -1) {
          header.splice(findIndexWarehouseName, 1);
        }
        const findIndexTrackingMaster = header.findIndex((t) => t.value === 'tracking_master');
        if (findIndexTrackingMaster > -1) {
          header.splice(findIndexTrackingMaster, 1);
        }
      } else if (this.isCollaborator || this.isPackager) {
        const findIndexAction = header.findIndex((t) => t.value === 'actions');
        if (findIndexAction > -1) {
          header.splice(findIndexAction, 1);
        }
      }
      return header;
    },
    listCountry() {
      return [{id: '', name: 'None'}, ...this.countries];
    },
    internalWarehouses() {
      return this.warehouses.filter((warehouse) => warehouse.type == 2);
    },
    publicWarehouses() {
      return this.warehouses.filter((warehouse) => warehouse.type == 1);
    },
    listServices() {
      let listItems = [
        {text: 'All Service', value: ''}
      ]
      this.services.forEach(service => {
        listItems.push({
          text: service.name,
          value: service.id
        })
      });
      return listItems;
    },
    DateRangeText() {
      return dateArr => {
        return dateArr.join(' ~ ')
      }
    },
    departedDate() {
      return item => {
        try {
          return item.departed_date.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    deliveredDate() {
      return item => {
        try {
          return item.delivered_date.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    receivedDate() {
      return item => {
        try {
          return item.receive_date.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    canPayMultiOrders() {
      return this.pickedItems.filter(order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        let totalFee = this.getTotalFee(order);
        return parseInt(totalFee) - parseInt(paidFee) === 0;
      }).length === 0
    },
    canBuyTracking() {
      return true;
      /*return this.pickedItems.filter(item => {
        return item.buy_tracking_status === this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS || item.buy_tracking_status === this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.NEED_BUY
      }).length === 0*/
    },

    getPaidFee() {
      return order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        return paidFee;
      }
    },
    getTotalFee() {
      return order => {
        let totalFee = 0;
        order.order_fees.forEach(f => totalFee += parseFloat(f.fee_amount));
        return parseInt(totalFee);
      }
    },
    getRemainFee() {
      return order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        let totalFee = this.getTotalFee(order);
        return totalFee - paidFee;
      }
    },
    toggleableSearchFields() {
      return Object.keys(this.searchFields).filter(sf => this.searchFields[sf].force_hidden !== true);
    },
    canSearchCustomer() {
      return this.isSuperAdmin || this.isSale || this.isManager || this.isSupervisor;
    },
    totalRemainingFee() {
      let sum = 0;
      this.pickedItems.forEach(order => {
        sum += this.getRemainFee(order)
      });
      return sum;
    }
  },
  methods: {
    ...mapActions({
      getListItems: 'order/get',
      exportListItems: 'order/exportItem',
      requestBuyTrackingNumber: 'order/requestBuyTrackingNumber',
      getUsers: "user/getUsers",
    }),
    onClickPayOrder(props) {
      this.dialog.payOrders = true;
      this.idsToPay = [props.item.id];
    },
    clickCopy() {
      this.dialog.detail = false;
      this.dialog.create = true;
      this.isCopyOrder = true;
    },
    onClickPrintLable() {
      if (this.selectedItem && this.selectedItem.id) {
        this.dialog.detail = false;
        this.dialog.printLabel = true;
        this.idsToPrintLabel = [this.selectedItem.id]
      }
    },
    resetFlagCopy() {
      this.isCopyOrder = false;
    },
    dateFilter(value) {
      if (!value) return;
      value = moment(value);
      return value.format('YYYY-MM-DD hh:mm');
    },
    exportItems() {
      this.dialog.exportOrder = true;
    },
    async onClickDownloadLabel() {
      this.dialog.downloadLabel = true;
    },
    clearBuyTrackingSearch() {
      this.searchFields.buy_tracking_date_from.value = '';
      this.searchFields.buy_tracking_date_to.value = '';
    },
    // clearInternalWarehouseSearch() {
    //   this.searchFields.internal_warehouse_id.value = '';
    // },
    clearPublicWarehouseSearch() {
      this.searchFields.warehouse_id.value = '';
    },
    clearCreatedOrderSearch() {
      this.searchFields.created_date_to.value = '';
      this.searchFields.created_date_from.value = '';
    },
    clearDepartDateSearch() {
      this.searchFields.departed_date_to.value = '';
      this.searchFields.delivered_date_from.value = '';
    },
    clearDeliveredDateSearch() {
      this.searchFields.delivered_date_to.value = '';
      this.searchFields.delivered_date_from.value = '';
    },
    clearReceivedDateSearch() {
      this.searchFields.received_date_to.value = '';
      this.searchFields.received_date_from.value = '';
    },
    async getCollaboratorsFromApi() {
      this.loadingCollaborators = true;
      let request = {
        params: {
          limit: -1,
          page: this.page,
          silent: true,
          with: 'role;socialAccount',
          search: 'role_id:' + this.CONSTANTS.ROLES.COLLABORATOR,
          searchFields: 'role_id:=',
          searchJoin: 'AND'
        }
      }
      let collaboratorsData = await this.getUsers(request);
      this.collaborators = [...this.collaborators, ...collaboratorsData.data.data];
      request.params.search = 'role_id:' + this.CONSTANTS.ROLES.SALE;
      let salesData = await this.getUsers(request);
      this.collaborators = [...this.collaborators, ...salesData.data.data];
      this.loadingCollaborators = false;
    },
  },
  watch: {
    departDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.departed_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.departed_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    deliveredDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.delivered_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.delivered_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    receivedDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.received_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.received_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    createdDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.created_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.created_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    buyTrackingDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.buy_tracking_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.buy_tracking_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // if(!this.authenticatedUser.id_card || !this.authenticatedUser.phone || this.authenticatedUser.id_card.trim().length === 0 || this.authenticatedUser.phone.trim().length === 0) {
    //   this.noticeWarning('Phone, Identity Card required!');
    //   this.$router.push({name: 'Profile'})
    //   return;
    // }
    this.searchFields.search_by_receiver.value = this.isReceiver
    this.getCollaboratorsFromApi();
  }
}
</script>
<style scoped lang="scss">
.list-orders::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }

  .order-customer-note {
    display: flex;
    max-width: 150px;

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.block-item-name {
  .item-name {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .item-code {
    font-size: 0.8rem;
  }
}

.internal-warehouse {
  font-size: 0.8rem;
}

.item-service-name {
  white-space: nowrap;
}

.switch-search::v-deep {
  .v-messages {
    display: none;
  }
}

.btn-unpaid-order {
  ::v-deep span {
    color: #fff !important;
  }
}

.small-text {
  font-size: 10px;
}
</style>
